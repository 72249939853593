.songpitch-landing {
    * {
      position: relative;
      z-index: 2 !important;
      text-shadow: 0px 0.5rem 1rem rgb(0 0 0 / 25%);
    }
  
    background-color: #1b245e;
    height: 100%;
    background: linear-gradient(0deg, #192474, #1d2c92);
    background: linear-gradient(180deg, #1d2c92 0%, #0b0a47 100%);
    background-size: 120% 120%;
    animation: gradient-animation 1s ease infinite;
    font-family: 'Barlow',
    sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: center;
  
    height: 100vh;
    width: 100vw;
  
    h1 {
      font-size: 6px;
      z-index: 1 !important;
    }
  
    h4 {
      color: #e5e5e5;
      max-width: 1024px;
      text-align: center;
      margin: 1em auto;
      font-size: max(2.2vw, 24px);
      padding: 0 60px;
    }
  
    h4 span {
      font-weight: 400;
    }
  
    h3 {
      color: #aaaaaa;
      max-width: 1024px;
      text-align: center;
      margin: 0 auto;
      font-size: max(1.8vw, 20px);
      padding: 0 60px;
      text-transform: uppercase;
      color: #42aefd;
    }
  
    p {
      color: #878787;
      max-width: 1024px;
      text-align: center;
      margin: 1em auto;
      font-size: max(1.4vw, 16px);
      padding: 0 60px;
    }
  
    p a {
      color: #42aefd;
      display: block;
    }
  
    .songpitch-logo {
      overflow: visible;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-content: flex-start;
      align-items: center;
      position: relative;
      filter: drop-shadow(0px 0.5rem 1rem rgba(0, 0, 0, 0.25));
      transition: transform 0.2s ease-in-out;
      /* change this depending on where it is used */
      width: max(25%, 200px);
      margin: 0 auto 4rem;
      max-width: 320px;
      /* position: absolute; top:50%; left: 50%; transform: translate(-50%, -50%); transform-origin: 0% 0%; */
    }
  
    .songpitch-logo:hover {
      transform: scale(1.1);
      /*translate(-50%, -50%); cursor: pointer;*/
    }
  
    .songpitch-logo:hover .outer-ring {
      animation-duration: 0.75s;
    }
  
    .songpitch-logo::before {
      display: block;
      padding-top: 100%;
      content: '';
    }
  
    .outer-ring {
      position: absolute;
      left: 0%;
      top: 0%;
      transform: translate(-50%, -50%);
      height: 100%;
      width: 100%;
      background-image: linear-gradient(135deg, #feed07 0%, #fe6a50 5%, #ed00aa 15%, #2fe3fe 50%, #8900ff 100%);
      border-radius: 50%;
      z-index: 1;
      animation-duration: 2s;
      animation-name: rotate-ring;
      animation-iteration-count: infinite;
    }
  
    .inner-ring {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 85%;
      height: 85%;
      background: #121d65;
      border-radius: 50%;
      z-index: 2;
      box-shadow: inset 0px 0.25rem 5rem rgba(0, 0, 0, 0.35);
      filter: drop-shadow(0px 0.5rem 1rem rgba(0, 0, 0, 0.25));
      background: #0a1454;
    }
  
    .logo {
      position: absolute;
      top: 50%;
      left: 50%;
      height: 100%;
      width: calc(100% - 2rem);
      transform: translate(-50%, -50%);
      text-align: center;
      display: flex;
      justify-content: center;
      width: min(80%, 90%);
    }
  
    .logo svg {
      width: 100%;
      height: auto;
      filter: drop-shadow(0px 0.25rem 0.4rem rgba(0, 0, 0, 0.25));
      filter: drop-shadow(0px 1.5rem 0.5rem rgba(0, 0, 0, 0.25));
      /* display: none; */
    }
  
    .logo::after {
      width: 100%;
      height: auto;
      content: '';
      position: absolute;
      height: 100%;
      /*background: url(SongPitch-white.svg) center center no-repeat; background-size: contain;*/
      filter: drop-shadow(0px 0.25rem 0.4rem rgba(0, 0, 0, 0.25));
      display: none;
    }
  
    .shadow-logo {
      width: 100%;
      height: auto;
      padding-bottom: 100%;
      border-radius: 50%;
      position: absolute;
      top: 57%;
      transform: scaleY(0.25);
      background: rgb(0, 0, 0);
      transition: all 0.1s ease-in-out;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 70%);
    }
  
    .songpitch-logo:hover .shadow-logo {
      top: 49%;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 70%);
      transform: scaleY(0.5);
      z-index: 1 !important;
      opacity: 0.5;
    }
  
    @keyframes rotate-ring {
      0% {
        transform: rotate(0deg);
      }
  
      100% {
        transform: rotate(360deg);
      }
    }
  
    @keyframes move {
      100% {
        transform: translate3d(0, 0, 1px) rotate(360deg);
      }
    }
  
    .sp-background {
      position: fixed;
      z-index: 1 !important;
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
      background: #0b0a47;
      overflow: hidden;
      opacity: 0.66;
    }
  
    .sp-background span {
      width: 50vmin;
      height: 50vmin;
      border-radius: 50vmin;
      backface-visibility: hidden;
      position: absolute;
      animation: move;
      animation-duration: 45;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
    }
  
    .sp-background span:nth-child(0) {
      color: #0c1a6b;
      top: 97%;
      left: 70%;
      animation-duration: 53s;
      animation-delay: -1s;
      transform-origin: 25vw 8vh;
      box-shadow: 100vmin 0 12.658128076800564vmin currentColor;
    }
  
    .sp-background span:nth-child(1) {
      color: #0c1a6b;
      top: 41%;
      left: 56%;
      animation-duration: 25s;
      animation-delay: -44s;
      transform-origin: -19vw 17vh;
      box-shadow: -100vmin 0 12.876241343260471vmin currentColor;
    }
  
    .sp-background span:nth-child(2) {
      color: #0c1a6b;
      top: 30%;
      left: 44%;
      animation-duration: 21s;
      animation-delay: -6s;
      transform-origin: 11vw -8vh;
      box-shadow: -100vmin 0 12.793289264935343vmin currentColor;
    }
  
    .sp-background span:nth-child(3) {
      color: #0c1a6b;
      top: 93%;
      left: 22%;
      animation-duration: 26s;
      animation-delay: -28s;
      transform-origin: 21vw -14vh;
      box-shadow: -100vmin 0 12.850656931561232vmin currentColor;
    }
  
    .sp-background span:nth-child(4) {
      color: #0c1a6b;
      top: 35%;
      left: 95%;
      animation-duration: 21s;
      animation-delay: -23s;
      transform-origin: 3vw -21vh;
      box-shadow: -100vmin 0 13.395304857325886vmin currentColor;
    }
  
    @keyframes gradient-animation {
      0% {
        background-position: 0% 50%;
      }
  
      50% {
        background-position: 100% 50%;
      }
  
      100% {
        background-position: 0% 50%;
      }
    }
  }
  
  